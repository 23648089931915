<template>
  <main ref="main">
    <!--这个页面是把首页的视频换成了轮播图-->
    <banner/>
    <news-view/>
    <business-view/>
    <section class="partner-view">
      <img src="../../assets/img/title_partners@2x.png" alt="合作伙伴" class="partner-view__title">
      <div class="partner-view__logo-container" ref="logo">
        <a href="http://ipwm.org/" target="_blank">
          <img src="../../assets/img/logo_04@2x.png" alt="国际专业财富管理协会">
        </a>
        <a href="http://www.fqchildren.com/" target="_blank">
          <img src="../../assets/img/logo_03@2x.png" alt="财商少年">
        </a>
      </div>
    </section>
  </main>
</template>

<script>
  import NewsView from "../home/childComp/NewsView"
  import BusinessView from "../home/childComp/BusinessView"
  import Banner from "../../components/common/Banner"

  export default {
    name: "test",
    components: {
      NewsView,
      BusinessView,
      Banner
    },
    metaInfo: {
      title: '乐享无限官网-banner',
      meta: [
        {
          name: 'keyWords',
          content: '乐享无限,乐享无限综合性自我增值平台,智菁通,广州智菁通信息科技有限公司,财富管理',
        },
        {
          name: 'description',
          content: '从2000年开始，乐享无限集团透过近二十年的FP（financial planning）财务规划专业知识，建立了FP财务规划行业的独特成功模式——培养专业的FP专才，透过LPPP以及ELPPP人生规划四部曲帮助大众做好个人及家庭的财务规划，达致财务自由。'
        }
      ]
    },
  }
</script>

<style lang="scss" scoped>
.partner-view {
  margin-top: 125px;
  .partner-view__title {
    display: block;
    margin: 0 auto;
  }
  text-align: center;
  .partner-view__logo-container {
    display: inline-block;
    margin-top: 60px;
    margin-bottom: 125px;
    a {
      display: inline-block;
      padding: 20px;
      &:hover {
        box-shadow: 0 0 5px 3px #e2ecf4;
      }
    }
    a:first-child {
      margin-right: 390px;
    }
  }
}
.video-view__mask {
  width: 100%;
  height: 100%;
  background: #0e0e0e;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
}
</style>

<style lang="scss">
  @keyframes fly-bottom {
    0% {transform: translateY(200%);}
    100% {transform: translateX(0);}
  }
  .fly-in-from-bottom {
    animation: fly-bottom 1s;
  }
</style>
