<template>
  <swiper :options="swiperOption" ref="mySwiper" class="swiper-container">
    <swiper-slide v-for="img in imgData" :key="img.title">
        <img :src="img.url" :alt="img.title" class="banner__img">
    </swiper-slide>

    <div class="swiper-pagination" slot="pagination"></div>

    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>

<script>
  import 'swiper/css/swiper.css'
  import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
  export default {
    name: "Banner",
    components: {
      Swiper,
      SwiperSlide
    },
    data() {
      return {
        // swiper选项
        swiperOption: {
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          loop: true,
          autoplay: {
            delay: 5000
          }
        },
        // 轮播图数据
        imgData: [
          {
            url: require('../../assets/img/banner/乐享官网banner4.jpg'),
            title: '乐享无限4'
          },
          {
            url: require('../../assets/img/banner/乐享官网banner1.jpg'),
            title: '乐享无限1'
          },
          {
            url: require('../../assets/img/banner/乐享官网banner2.jpg'),
            title: '乐享无限2'
          },
          {
            url: require('../../assets/img/banner/乐享官网banner3.jpg'),
            title: '乐享无限3'
          },
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>
.banner__img {
  display: block;
  width: 100%;
}
::v-deep .swiper-pagination {
  bottom: 60px;
}
::v-deep .swiper-pagination-bullet {
  border-radius: 0;
  height: 30px;
  width: 30px;
  opacity: 1;
  margin: 0 !important;
  background: url("../../assets/img/banner/pagination.png") no-repeat;
  background-position: center;
}
::v-deep .swiper-pagination-bullet-active {
  background: url("../../assets/img/banner/pagination_active.png") ;
  background-position: center;
}
::v-deep .swiper-button-prev {
  left: 100px;
  color: #ffffff;
}
::v-deep .swiper-button-next {
  right: 100px;
  color: #ffffff;
}
</style>
